<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getSettingsErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('superAdmin:clientSettings')
                                }}</v-stepper-step
                            >
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getSettingsErrors.stepOne.validation,
                                ]"
                                editable
                                step="2"
                                >{{
                                    $t('superAdmin:bussinesLocation')
                                }}</v-stepper-step
                            >

                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                            <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="
                                                $t('superAdmin:aboutCompany')
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.applicationLink
                                            "
                                            :label="
                                                $t('superAdmin:applicationLink')
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.companyName
                                            "
                                            :label="
                                                $t('superAdmin:companyName')
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.companyAddress
                                            "
                                            :label="
                                                $t('superAdmin:companyAddress')
                                            "
                                        ></v-text-field
                                    ></v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.companyEmail
                                            "
                                            :label="
                                                $t('superAdmin:companyEmail')
                                            "
                                        ></v-text-field></v-col
                                    ><v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.companyPhone
                                            "
                                            :label="
                                                $t('superAdmin:companyPhone')
                                            "
                                        ></v-text-field
                                    ></v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="getSettingsModal.website"
                                            :label="$t('superAdmin:website')"
                                        ></v-text-field
                                    ></v-col>

                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.adminEmail
                                            "
                                            :label="$t('superAdmin:adminEmail')"
                                        ></v-text-field
                                    ></v-col>
                                    <!-- <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.defaultRegistryFolder
                                            "
                                            :label="
                                                $t('superAdmin:defaultFolder')
                                            "
                                        ></v-text-field></v-col
                                > -->
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-mask="nipMask"
                                            v-model="getSettingsModal.nip"
                                            :label="'NIP'"
                                        ></v-text-field></v-col
                                ></v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="getSettingsModal.bdo"
                                            :label="'BDO'"
                                        ></v-text-field
                                    ></v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.authenticationName
                                            "
                                            :label="
                                                $t(
                                                    'superAdmin:authenticationName'
                                                )
                                            "
                                        ></v-text-field
                                    ></v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.authenticationPassword
                                            "
                                            :label="
                                                $t(
                                                    'superAdmin:authenticationPassword'
                                                )
                                            "
                                        ></v-text-field
                                    ></v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="getSettingsModal.bankName"
                                            :label="'Nazwa banku'"
                                        ></v-text-field
                                    ></v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            :rules="[numberRules]"
                                            v-model="
                                                getSettingsModal.bankAccountNumber
                                            "
                                            :label="'Numer konta bankowego'"
                                        ></v-text-field
                                    ></v-col>
                                </v-row>

                                <!-- <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('superAdmin:emailData')"
                                        />
                                    </v-col>
                                </v-row> -->
                                <!-- <v-row>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.secretariatEmail
                                            "
                                            :label="
                                                $t(
                                                    'superAdmin:secretariatEmail'
                                                )
                                            "
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.smtpSerwer
                                            "
                                            :label="$t('superAdmin:smtpSerwer')"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="getSettingsModal.smtpPort"
                                            :label="$t('superAdmin:smtpPort')"
                                        ></v-text-field
                                    ></v-col>
                                </v-row> -->
                                <!-- <v-row>
                                     -->
                                <!-- <v-col cols="4">
                                        <v-text-field
                                            v-model="
                                                getSettingsModal.authenticationPassword
                                            "
                                            :label="
                                                $t(
                                                    'superAdmin:authenticationPassword'
                                                )
                                            "
                                        ></v-text-field
                                    ></v-col> -->
                                <!-- </v-row> -->
                            </v-stepper-content>
                            <v-stepper-content step="2">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="
                                                $t(
                                                    'superAdmin:bussinesLocation'
                                                )
                                            "
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.companyCountry
                                            "
                                            :label="
                                                $t('superAdmin:companyCountry')
                                            "
                                        ></v-text-field
                                    ></v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.companyCity
                                            "
                                            :label="
                                                $t('superAdmin:companyCity')
                                            "
                                        ></v-text-field
                                    ></v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.companyPostCode
                                            "
                                            :label="
                                                $t('superAdmin:companyPostCode')
                                            "
                                        ></v-text-field
                                    ></v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.companyStreet
                                            "
                                            :label="
                                                $t('superAdmin:companyStreet')
                                            "
                                        ></v-text-field
                                    ></v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.companyHouseNumber
                                            "
                                            :label="
                                                $t(
                                                    'superAdmin:companyHouseNumber'
                                                )
                                            "
                                        ></v-text-field
                                    ></v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.companyApartmentNumber
                                            "
                                            :label="
                                                $t(
                                                    'superAdmin:companyApartmentNumber'
                                                )
                                            "
                                        ></v-text-field
                                    ></v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.companyServiceEmail
                                            "
                                            :label="
                                                $t(
                                                    'superAdmin:companyServiceEmail'
                                                )
                                            "
                                        ></v-text-field
                                    ></v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.estimatesPDFBoxText
                                            "
                                            :label="
                                                $t(
                                                    'estimates:estimatesPDFBoxText'
                                                )
                                            "
                                        ></v-text-field
                                    ></v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.estimatesPDFFooterText
                                            "
                                            :label="
                                                $t(
                                                    'estimates:estimatesPDFFooterText'
                                                )
                                            "
                                        ></v-text-field
                                    ></v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.estimatesPDFGuaranteText
                                            "
                                            :label="
                                                $t(
                                                    'estimates:estimatesPDFGuaranteText'
                                                )
                                            "
                                        ></v-text-field
                                    ></v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.companyServicePhoneNumber
                                            "
                                            :label="
                                                $t(
                                                    'estimates:companyServicePhoneNumber'
                                                )
                                            "
                                        ></v-text-field
                                    ></v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.emailServiceEstimatesHost
                                            "
                                            :label="'Wyceny - SMTP host'"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.emailServiceEstimatesPort
                                            "
                                            :label="'Wyceny - SMTP port'"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.emailServiceEstimatesLogin
                                            "
                                            :label="'Wyceny - SMTP login'"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-model="
                                                getSettingsModal.emailServiceEstimatesPass
                                            "
                                            :label="'Wyceny - SMTP pass'"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-stepper-content>
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { numberRules } from '../../../../validators/formValidation'
import { nipMask } from '../../../../validators/formValidation'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            date: '',
            step: 1,
            editable: true,
            numberRules: numberRules,
            nipMask: nipMask,
        }
    },

    computed: {
        ...mapGetters([
            'getSettingsErrors',
            'getSettingsModal',
            'getProfileDetails',
        ]),
    },
}
</script>
