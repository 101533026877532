<template>
    <div>
        <v-btn
            class="buttons buttons--add"
            :disabled="disabled"
            @click="save()"
        >
            {{ $t('global:save') }}
        </v-btn>
    </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },
    data() {
        return {
            disabled: false,
        }
    },

    methods: {
        ...mapActions(['saveSettings', 'fetchSettings']),
        async save() {
            this.disabled = true
            let success = await this.saveSettings()
            if (success) {
                this.$emit('closeModal')
            }
            this.disabled = false
        },
    },
}
</script>
