var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getSettingsErrors.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('superAdmin:clientSettings')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('superAdmin:licenses')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[(
                                            _vm.getProfileDetails.isSuperAdmin
                                        )?_c('v-text-field',{attrs:{"dense":"","error-messages":_vm.getSettingsErrors.stepTwo.fields
                                                .licensesNumber,"label":_vm.$t('superAdmin:licensesNumber')},model:{value:(
                                            _vm.getSettingsModal.licensesNumber
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "licensesNumber", $$v)},expression:"\n                                            getSettingsModal.licensesNumber\n                                        "}}):_vm._e()],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }