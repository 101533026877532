<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card
                    elevation="0"
                    height="calc( 100vh - 288px  )"
                    class="pa-5"
                >
                    <v-row>
                        <v-col cols="6">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <Separator
                                        :text="$t('superAdmin:aboutCompany')"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="3" class="mb-5">
                                    <div class="p-relative">
                                        <img
                                            class="logo-client"
                                            :src="`${path}`"
                                            alt="entelli logo"
                                        />
                                        <div>
                                            <v-tooltip bottom>
                                                <template
                                                    v-slot:activator="{ on }"
                                                >
                                                    <v-btn
                                                        class="logo__edit"
                                                        @click="openLogo = true"
                                                        width="25"
                                                        v-on="on"
                                                        height="25"
                                                        icon
                                                    >
                                                        <v-icon
                                                            size="16"
                                                            color="white"
                                                            >mdi-pencil</v-icon
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span>{{
                                                    $t('superAdmin:changeLogo')
                                                }}</span>
                                            </v-tooltip>
                                        </div>
                                    </div></v-col
                                >
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'companyName',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="$t('superAdmin:companyName')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'applicationLink',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="
                                            $t('superAdmin:applicationLink')
                                        "
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'companyAddress',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="$t('superAdmin:companyAddress')"
                                    ></v-text-field
                                ></v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'companyEmail',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="$t('superAdmin:companyEmail')"
                                    ></v-text-field></v-col
                                ><v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'companyPhone',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="$t('superAdmin:companyPhone')"
                                    ></v-text-field
                                ></v-col>
                            </v-row>
                            <v-row
                                ><v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'adminEmail',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="$t('superAdmin:adminEmail')"
                                    ></v-text-field></v-col
                                ><v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'website',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="$t('superAdmin:website')"
                                    ></v-text-field
                                ></v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(getSettingsDetails, 'nip', '')
                                        "
                                        readonly
                                        :label="'NIP'"
                                    ></v-text-field></v-col
                            ></v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(getSettingsDetails, 'bdo', '')
                                        "
                                        readonly
                                        :label="'BDO'"
                                    ></v-text-field
                                ></v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'authenticationName',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="
                                            $t('superAdmin:authenticationName')
                                        "
                                    ></v-text-field
                                ></v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'authenticationPassword',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="
                                            $t(
                                                'superAdmin:authenticationPassword'
                                            )
                                        "
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'bankName',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="'Nazwa banku'"
                                    ></v-text-field
                                ></v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'bankAccountNumber',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="'Numer konta bankowego'"
                                    ></v-text-field
                                ></v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="6">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <Separator
                                        :text="
                                            $t('superAdmin:bussinesLocation')
                                        "
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'companyCountry',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="$t('superAdmin:companyCountry')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'companyCity',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="$t('superAdmin:companyCity')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'companyPostCode',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="
                                            $t('superAdmin:companyPostCode')
                                        "
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'companyStreet',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="$t('superAdmin:companyStreet')"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'companyHouseNumber',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="
                                            $t('superAdmin:companyHouseNumber')
                                        "
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'companyApartmentNumber',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="
                                            $t(
                                                'superAdmin:companyApartmentNumber'
                                            )
                                        "
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'companyServiceEmail',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="
                                            $t('superAdmin:companyServiceEmail')
                                        "
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'companyServicePhoneNumber',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="
                                            $t(
                                                'estimates:companyServicePhoneNumber'
                                            )
                                        "
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'estimatesPDFBoxText',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="
                                            $t('estimates:estimatesPDFBoxText')
                                        "
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'estimatesPDFFooterText',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="
                                            $t(
                                                'estimates:estimatesPDFFooterText'
                                            )
                                        "
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'estimatesPDFGuaranteText',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="
                                            $t(
                                                'estimates:estimatesPDFGuaranteText'
                                            )
                                        "
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'emailServiceEstimatesHost',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="'Wyceny - SMTP host'"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'emailServiceEstimatesPort',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="'Wyceny - SMTP port'"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'emailServiceEstimatesLogin',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="'Wyceny - SMTP login'"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'emailServiceEstimatesPass',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="'Wyceny - SMTP pass'"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-col>

                        <!-- <v-col cols="5">
                            <v-row>
                                <v-col cols="12" md="12">
                                    <Separator
                                        :text="$t('superAdmin:licenses')"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'licensesNumber',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="$t('superAdmin:licensesNumber')"
                                    ></v-text-field>
                                </v-col> </v-row
                            ><v-row>
                                <v-col cols="12" md="12">
                                    <Separator
                                        :text="$t('superAdmin:security')"
                                    />
                                </v-col>
                                <v-col cols="5"
                                    ><v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'sessionTime',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="$t('superAdmin:sessionTime')"
                                    ></v-text-field
                                ></v-col>
                                <v-col cols="5"
                                    ><v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'loginAttempts',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="$t('superAdmin:loginAttempts')"
                                    ></v-text-field
                                ></v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12"
                                    ><v-checkbox
                                        dense
                                        v-model="
                                            getSettingsDetails.severalDevices
                                        "
                                        readonly
                                        :label="$t('superAdmin:severalDevices')"
                                    ></v-checkbox
                                ></v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <Separator
                                        :text="$t('superAdmin:rentSettlemenst')"
                                    />
                                </v-col>
                                <v-col cols="5"
                                    ><v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'defaultValue',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="$t('superAdmin:defaultValue')"
                                    ></v-text-field
                                ></v-col>
                                <v-col cols="5"
                                    ><v-text-field
                                        dense
                                        :value="
                                            $get(
                                                getSettingsDetails,
                                                'AVGAmount',
                                                ''
                                            )
                                        "
                                        readonly
                                        :label="$t('superAdmin:AVGAmount')"
                                    ></v-text-field
                                ></v-col>
                            </v-row>
                        </v-col> -->

                        <!-- @slot Use this slot to add buttons-->
                        <slot name="buttons"></slot>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <Modal
            :title="$t('global:settings')"
            :open="open"
            :height="'570'"
            v-on:close="closeModal()"
        >
            <AEContentSettings slot="AEContent" />
            <ButtonsEditSettings @closeModal="open = false" slot="buttons" />
        </Modal>
        <Modal
            :title="$t('superAdmin:editLogo')"
            :open="openLogo"
            width="700"
            v-on:close="closeModalLogo"
        >
            <AEContent slot="AEContent" />
            <Buttons @close-modal="openLogo = false" slot="buttons" />
        </Modal>
        <div class="d-flex justify-end">
            <OpenModalButtons @openModal="openModal()"></OpenModalButtons>
        </div>
    </v-container>
</template>
<script>
import OpenModalButtons from './../../../../components/Admin/Settings/Details/Buttons/Buttons'
import AEContent from './../LogoModal/Content'
import AEContentSettings from './../../../../components/Admin/Settings/Modal/Content'
import Buttons from './../LogoModal/Buttons'
import ButtonsEditSettings from '../Modal/Buttons'
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
    data() {
        return {
            open: false,
            openLogo: false,
            path: `${process.env.VUE_APP_SERVER}/api/v2/getAppLogo`,
        }
    },
    methods: {
        ...mapActions(['fetchSettings']),
        ...mapMutations(['clearSettingsErrors']),
        closeModal() {
            this.open = false
        },
        closeModalLogo() {
            this.openLogo = false
            this.$forceUpdate()
        },
        openModal() {
            this.open = true
            this.clearSettingsErrors()
        },
        openModalLogo() {
            this.openLogo = true
            this.clearSettingsErrors()
        },
        // logoPicture(payload) {
        //     let picture = `${process.env.VUE_APP_SERVER}/secured/logo/client_logo.png`
        //     console.log(picture)
        //     console.log('payload', payload)
        //     if (payload?.url) {
        //         picture = payload.url
        //     }

        //     return picture
        // },
    },
    computed: {
        ...mapGetters(['getSettingsDetails']),
    },
    components: {
        AEContent,
        Buttons,
        OpenModalButtons,
        AEContentSettings,
        ButtonsEditSettings,
    },
}
</script>
<style lang="sass" scoped>
.logo-client
    width: 100%
</style>
