var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5",attrs:{"elevation":"0","height":"calc( 100vh - 288px  )"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"6"}},[_c('Separator',{attrs:{"text":_vm.$t('global:rentSettlements')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(_vm.getSettingsDetails, 'defaultValue', ''),"readonly":"","label":_vm.$t('superAdmin:defaultValue')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(_vm.getSettingsDetails, 'AVGAmount', ''),"readonly":"","label":_vm.$t('superAdmin:AVGAmount')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                    _vm.getSettingsDetails,
                                    'invoiceSchema',
                                    ''
                                ),"readonly":"","label":'Schemat faktur'}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","readonly":"","label":'Umowy najmu - SMTP host'},model:{value:(
                                _vm.getSettingsDetails.emailServiceRentSettlementHost
                            ),callback:function ($$v) {_vm.$set(_vm.getSettingsDetails, "emailServiceRentSettlementHost", $$v)},expression:"\n                                getSettingsDetails.emailServiceRentSettlementHost\n                            "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","readonly":"","label":'Umowy najmu - SMTP port'},model:{value:(
                                _vm.getSettingsDetails.emailServiceRentSettlementPort
                            ),callback:function ($$v) {_vm.$set(_vm.getSettingsDetails, "emailServiceRentSettlementPort", $$v)},expression:"\n                                getSettingsDetails.emailServiceRentSettlementPort\n                            "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","readonly":"","label":'Umowy najmu - SMTP login'},model:{value:(
                                _vm.getSettingsDetails.emailServiceRentSettlementLogin
                            ),callback:function ($$v) {_vm.$set(_vm.getSettingsDetails, "emailServiceRentSettlementLogin", $$v)},expression:"\n                                getSettingsDetails.emailServiceRentSettlementLogin\n                            "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","readonly":"","label":'Umowy najmu - SMTP pass'},model:{value:(
                                _vm.getSettingsDetails.emailServiceRentSettlementPass
                            ),callback:function ($$v) {_vm.$set(_vm.getSettingsDetails, "emailServiceRentSettlementPass", $$v)},expression:"\n                                getSettingsDetails.emailServiceRentSettlementPass\n                            "}})],1)],1)],1)],1)],1),_c('Modal',{attrs:{"title":_vm.$t('global:settings'),"open":_vm.open,"height":'570'},on:{"close":function($event){return _vm.closeModal()}}},[_c('AEContentSettings',{attrs:{"slot":"AEContent"},slot:"AEContent"}),_c('ButtonsEditSettings',{attrs:{"slot":"buttons"},on:{"closeModal":function($event){_vm.open = false}},slot:"buttons"})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('OpenModalButtons',{on:{"openModal":function($event){return _vm.openModal()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }