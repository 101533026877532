var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getSettingsErrors.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('superAdmin:clientSettings')))]),_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getSettingsErrors.stepOne.validation,
                            ],"editable":"","step":"2"}},[_vm._v(_vm._s(_vm.$t('superAdmin:bussinesLocation')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('superAdmin:aboutCompany')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('superAdmin:applicationLink')},model:{value:(
                                            _vm.getSettingsModal.applicationLink
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "applicationLink", $$v)},expression:"\n                                            getSettingsModal.applicationLink\n                                        "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('superAdmin:companyName')},model:{value:(
                                            _vm.getSettingsModal.companyName
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "companyName", $$v)},expression:"\n                                            getSettingsModal.companyName\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('superAdmin:companyAddress')},model:{value:(
                                            _vm.getSettingsModal.companyAddress
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "companyAddress", $$v)},expression:"\n                                            getSettingsModal.companyAddress\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('superAdmin:companyEmail')},model:{value:(
                                            _vm.getSettingsModal.companyEmail
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "companyEmail", $$v)},expression:"\n                                            getSettingsModal.companyEmail\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('superAdmin:companyPhone')},model:{value:(
                                            _vm.getSettingsModal.companyPhone
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "companyPhone", $$v)},expression:"\n                                            getSettingsModal.companyPhone\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('superAdmin:website')},model:{value:(_vm.getSettingsModal.website),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "website", $$v)},expression:"getSettingsModal.website"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('superAdmin:adminEmail')},model:{value:(
                                            _vm.getSettingsModal.adminEmail
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "adminEmail", $$v)},expression:"\n                                            getSettingsModal.adminEmail\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.nipMask),expression:"nipMask"}],attrs:{"dense":"","label":'NIP'},model:{value:(_vm.getSettingsModal.nip),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "nip", $$v)},expression:"getSettingsModal.nip"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":'BDO'},model:{value:(_vm.getSettingsModal.bdo),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "bdo", $$v)},expression:"getSettingsModal.bdo"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t(
                                                'superAdmin:authenticationName'
                                            )},model:{value:(
                                            _vm.getSettingsModal.authenticationName
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "authenticationName", $$v)},expression:"\n                                            getSettingsModal.authenticationName\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t(
                                                'superAdmin:authenticationPassword'
                                            )},model:{value:(
                                            _vm.getSettingsModal.authenticationPassword
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "authenticationPassword", $$v)},expression:"\n                                            getSettingsModal.authenticationPassword\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":'Nazwa banku'},model:{value:(_vm.getSettingsModal.bankName),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "bankName", $$v)},expression:"getSettingsModal.bankName"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","rules":[_vm.numberRules],"label":'Numer konta bankowego'},model:{value:(
                                            _vm.getSettingsModal.bankAccountNumber
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "bankAccountNumber", $$v)},expression:"\n                                            getSettingsModal.bankAccountNumber\n                                        "}})],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t(
                                                'superAdmin:bussinesLocation'
                                            )}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('superAdmin:companyCountry')},model:{value:(
                                            _vm.getSettingsModal.companyCountry
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "companyCountry", $$v)},expression:"\n                                            getSettingsModal.companyCountry\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('superAdmin:companyCity')},model:{value:(
                                            _vm.getSettingsModal.companyCity
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "companyCity", $$v)},expression:"\n                                            getSettingsModal.companyCity\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('superAdmin:companyPostCode')},model:{value:(
                                            _vm.getSettingsModal.companyPostCode
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "companyPostCode", $$v)},expression:"\n                                            getSettingsModal.companyPostCode\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('superAdmin:companyStreet')},model:{value:(
                                            _vm.getSettingsModal.companyStreet
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "companyStreet", $$v)},expression:"\n                                            getSettingsModal.companyStreet\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t(
                                                'superAdmin:companyHouseNumber'
                                            )},model:{value:(
                                            _vm.getSettingsModal.companyHouseNumber
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "companyHouseNumber", $$v)},expression:"\n                                            getSettingsModal.companyHouseNumber\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t(
                                                'superAdmin:companyApartmentNumber'
                                            )},model:{value:(
                                            _vm.getSettingsModal.companyApartmentNumber
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "companyApartmentNumber", $$v)},expression:"\n                                            getSettingsModal.companyApartmentNumber\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t(
                                                'superAdmin:companyServiceEmail'
                                            )},model:{value:(
                                            _vm.getSettingsModal.companyServiceEmail
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "companyServiceEmail", $$v)},expression:"\n                                            getSettingsModal.companyServiceEmail\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t(
                                                'estimates:estimatesPDFBoxText'
                                            )},model:{value:(
                                            _vm.getSettingsModal.estimatesPDFBoxText
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "estimatesPDFBoxText", $$v)},expression:"\n                                            getSettingsModal.estimatesPDFBoxText\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t(
                                                'estimates:estimatesPDFFooterText'
                                            )},model:{value:(
                                            _vm.getSettingsModal.estimatesPDFFooterText
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "estimatesPDFFooterText", $$v)},expression:"\n                                            getSettingsModal.estimatesPDFFooterText\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t(
                                                'estimates:estimatesPDFGuaranteText'
                                            )},model:{value:(
                                            _vm.getSettingsModal.estimatesPDFGuaranteText
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "estimatesPDFGuaranteText", $$v)},expression:"\n                                            getSettingsModal.estimatesPDFGuaranteText\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t(
                                                'estimates:companyServicePhoneNumber'
                                            )},model:{value:(
                                            _vm.getSettingsModal.companyServicePhoneNumber
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "companyServicePhoneNumber", $$v)},expression:"\n                                            getSettingsModal.companyServicePhoneNumber\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":'Wyceny - SMTP host'},model:{value:(
                                            _vm.getSettingsModal.emailServiceEstimatesHost
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "emailServiceEstimatesHost", $$v)},expression:"\n                                            getSettingsModal.emailServiceEstimatesHost\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":'Wyceny - SMTP port'},model:{value:(
                                            _vm.getSettingsModal.emailServiceEstimatesPort
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "emailServiceEstimatesPort", $$v)},expression:"\n                                            getSettingsModal.emailServiceEstimatesPort\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":'Wyceny - SMTP login'},model:{value:(
                                            _vm.getSettingsModal.emailServiceEstimatesLogin
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "emailServiceEstimatesLogin", $$v)},expression:"\n                                            getSettingsModal.emailServiceEstimatesLogin\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":'Wyceny - SMTP pass'},model:{value:(
                                            _vm.getSettingsModal.emailServiceEstimatesPass
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "emailServiceEstimatesPass", $$v)},expression:"\n                                            getSettingsModal.emailServiceEstimatesPass\n                                        "}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }