<template>
    <v-container>
        <AdminHeader
            :registryTitle="$t('global:settings')"
            :registryUrl="'registries'"
        />
        <Main
            class="p-relative"
            :activeTab="'Informacje o firmie'"
            :informationTabs="false"
            :logsTabs="false"
            :notesTabs="false"
            :alertsTabs="false"
            :tasksTabs="false"
            :attachmentsTabs="false"
            :items="getAvailableTabs"
            ><v-tab-item
                v-for="item in getAvailableTabs"
                :key="item.id"
                slot="tabItems"
                :id="item.id"
            >
                <component :is="item.component"></component>
            </v-tab-item>
        </Main>
    </v-container>
</template>
<script>
import OpenModalButtons from './../../../components/Admin/Settings/Details/Buttons/Buttons'
import Details from '../../../components/Admin/Settings/Details/Details.vue'
import LicencesTab from '../../../components/Admin/Settings/Details/Tabs/LicencesTab.vue'
import SecurityTab from '../../../components/Admin/Settings/Details/Tabs/SecurityTab.vue'
import RentSettlementTab from '../../../components/Admin/Settings/Details/Tabs/RentSettlmentTab.vue'
import APITab from '../../../components/Admin/Settings/Details/Tabs/APITab.vue'

import AEContent from './../../../components/Admin/Settings/Modal/Content'
import Buttons from './../../../components/Admin/Settings/Modal/Buttons'
import store from '../../../store/index'
import { mapMutations, mapGetters, mapActions } from 'vuex'
export default {
    i18nOptions: { namespaces: ['users', 'global'] },
    components: {
        AEContent,
        OpenModalButtons,
        Details,
        Buttons,
        
    },
    methods: {
        ...mapActions(['fetchSettings']),
        ...mapMutations(['clearSettingsErrors']),
        closeModal() {
            this.open = false
        },
        openModal() {
            this.open = true
            this.clearSettingsErrors()
        },
    },
    computed: {
        ...mapGetters([]),
        getAvailableTabs: {
            get() {
                this.items = [
                    {
                        name: 'Informacje o firmie',
                        id: 'companyInfo',
                        icon: 'mdi-domain',
                        component: Details,
                        visibility: true,
                    },
                    {
                        name: 'Licencje',
                        id: 'licences',
                        icon: 'mdi-badge-account-horizontal',
                        component: LicencesTab,
                        visibility: true,
                    },
                    {
                        name: 'Bezpieczeństwo',
                        id: 'security',
                        icon: 'mdi-server-security',
                        component: SecurityTab,
                        visibility: true,
                    },
                    {
                        name: this.$t('global:rentSettlements'),
                        id: 'rentSettlment',
                        icon: 'mdi-plus',
                        component: RentSettlementTab,
                        visibility: true,
                    },
                     {
                        name: 'API',
                        id: 'api',
                        icon: 'mdi-api',
                        component: APITab,
                        visibility: true,
                    },
                ]
                return this.items.filter(e => e.visibility)
            },
        },
    },
    data: () => ({
        open: false,
    }),
    beforeRouteEnter(to, from, next) {
        store.dispatch('fetchSettings', {
            type: 'add',
            next,
        })
    },
}
</script>
