var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-stepper',{staticClass:"elevation-0",model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[[_c('v-stepper-header',{staticClass:"elevation-0 stepperHeader"},[_c('v-divider'),_c('v-stepper-step',{attrs:{"rules":[
                                () => _vm.getSettingsErrors.stepOne.validation,
                            ],"editable":"","step":"1"}},[_vm._v(_vm._s(_vm.$t('superAdmin:security')))]),_c('v-divider')],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('superAdmin:security')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('superAdmin:loginAttempts')},model:{value:(
                                            _vm.getSettingsModal.loginAttempts
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "loginAttempts", $$v)},expression:"\n                                            getSettingsModal.loginAttempts\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"dense":"","label":_vm.$t('superAdmin:severalDevices')},model:{value:(
                                            _vm.getSettingsModal.severalDevices
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "severalDevices", $$v)},expression:"\n                                            getSettingsModal.severalDevices\n                                        "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":'Adres hosta'},model:{value:(
                                            _vm.getSettingsModal.emailServiceEstimatesHost
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "emailServiceEstimatesHost", $$v)},expression:"\n                                            getSettingsModal.emailServiceEstimatesHost\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":'Port'},model:{value:(
                                            _vm.getSettingsModal.emailServiceEstimatesPort
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "emailServiceEstimatesPort", $$v)},expression:"\n                                            getSettingsModal.emailServiceEstimatesPort\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":'Login'},model:{value:(
                                            _vm.getSettingsModal.emailServiceEstimatesLogin
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "emailServiceEstimatesLogin", $$v)},expression:"\n                                            getSettingsModal.emailServiceEstimatesLogin\n                                        "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","label":'Hasło'},model:{value:(
                                            _vm.getSettingsModal.emailServiceEstimatesPass
                                        ),callback:function ($$v) {_vm.$set(_vm.getSettingsModal, "emailServiceEstimatesPass", $$v)},expression:"\n                                            getSettingsModal.emailServiceEstimatesPass\n                                        "}})],1)],1)],1)],1)]],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }