<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card
                    elevation="0"
                    height="calc( 100vh - 288px  )"
                    class="pa-5"
                >
                    <v-row>
                        <v-col cols="6" md="6">
                            <Separator :text="$t('superAdmin:licenses')" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-text-field
                                dense
                                :value="
                                    $get(
                                        getSettingsDetails,
                                        'licensesNumber',
                                        ''
                                    )
                                "
                                readonly
                                :label="$t('superAdmin:licensesNumber')"
                            ></v-text-field>
                        </v-col>

                        <v-row>
                            <v-col>
                                <v-row> </v-row>
                            </v-col>
                        </v-row>

                        <!-- @slot Use this slot to add buttons-->
                        <slot name="buttons"></slot>
                    </v-row>
                </v-card>
            </v-col> </v-row
        ><Modal
            :title="$t('global:settings')"
            :open="open"
            :height="'570'"
            v-on:close="closeModal()"
        >
            <AEContentSettings slot="AEContent" />
            <ButtonsEditSettings @closeModal="open = false" slot="buttons" />
        </Modal>
        <div class="d-flex justify-end">
            <OpenModalButtons @openModal="openModal()"></OpenModalButtons>
        </div>
    </v-container>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

import AEContentSettings from './../../../../../components/Admin/Settings/Modal/ContentLicences'
import OpenModalButtons from './../../../../../components/Admin/Settings/Details/Buttons/Buttons'
import ButtonsEditSettings from '../../Modal/Buttons'
export default {
    data() {
        return {
            open: false,
            path: `${process.env.VUE_APP_SERVER}/secured/logo/`,
        }
    },
    methods: {
        ...mapMutations(['clearSettingsErrors']),
        openModal() {
            this.open = true
            this.clearSettingsErrors()
        },
        closeModal() {
            this.open = false;
        }
    },
    computed: {
        ...mapGetters(['getSettingsDetails']),
    },
    components: {
        AEContentSettings,
        ButtonsEditSettings,
        OpenModalButtons,
    },
}
</script>
<style lang="sass" scoped>
.logo-client
    width: 100%
</style>
