<template>
    <v-container class="pt-0">
        <v-row>
            <v-col cols="12" class="pa-0">
                <v-stepper v-model="step" class="elevation-0">
                    <template>
                        <v-stepper-header class="elevation-0 stepperHeader">
                            <v-divider></v-divider>
                            <v-stepper-step
                                :rules="[
                                    () => getSettingsErrors.stepOne.validation,
                                ]"
                                editable
                                step="1"
                                >{{
                                    $t('superAdmin:clientSettings')
                                }}</v-stepper-step
                            >
                        
                         
                            <v-divider></v-divider>
                        </v-stepper-header>
                        <v-stepper-items>
                             <v-stepper-content step="1">
                                <v-row>
                                    <v-col cols="12" md="12">
                                        <Separator
                                            :text="$t('superAdmin:licenses')"
                                        />
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field
                                            dense
                                            v-if="
                                                getProfileDetails.isSuperAdmin
                                            "
                                            v-model="
                                                getSettingsModal.licensesNumber
                                            "
                                            :error-messages="
                                                getSettingsErrors.stepTwo.fields
                                                    .licensesNumber
                                            "
                                            :label="
                                                $t('superAdmin:licensesNumber')
                                            "
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                               
                            </v-stepper-content>
                         
                        </v-stepper-items>
                    </template>
                </v-stepper>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            date: '',
            step: 1,
            editable: true,
        }
    },

    computed: {
        ...mapGetters([
            'getSettingsErrors',
            'getSettingsModal',
            'getProfileDetails',
        ]),
    },
}
</script>
