var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-5",attrs:{"elevation":"0","height":"calc( 100vh - 288px  )"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('superAdmin:aboutCompany')}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mb-5",attrs:{"cols":"3"}},[_c('div',{staticClass:"p-relative"},[_c('img',{staticClass:"logo-client",attrs:{"src":`${_vm.path}`,"alt":"entelli logo"}}),_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"logo__edit",attrs:{"width":"25","height":"25","icon":""},on:{"click":function($event){_vm.openLogo = true}}},on),[_c('v-icon',{attrs:{"size":"16","color":"white"}},[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('superAdmin:changeLogo')))])])],1)])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'companyName',
                                            ''
                                        ),"readonly":"","label":_vm.$t('superAdmin:companyName')}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'applicationLink',
                                            ''
                                        ),"readonly":"","label":_vm.$t('superAdmin:applicationLink')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'companyAddress',
                                            ''
                                        ),"readonly":"","label":_vm.$t('superAdmin:companyAddress')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'companyEmail',
                                            ''
                                        ),"readonly":"","label":_vm.$t('superAdmin:companyEmail')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'companyPhone',
                                            ''
                                        ),"readonly":"","label":_vm.$t('superAdmin:companyPhone')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'adminEmail',
                                            ''
                                        ),"readonly":"","label":_vm.$t('superAdmin:adminEmail')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'website',
                                            ''
                                        ),"readonly":"","label":_vm.$t('superAdmin:website')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(_vm.getSettingsDetails, 'nip', ''),"readonly":"","label":'NIP'}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(_vm.getSettingsDetails, 'bdo', ''),"readonly":"","label":'BDO'}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'authenticationName',
                                            ''
                                        ),"readonly":"","label":_vm.$t('superAdmin:authenticationName')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'authenticationPassword',
                                            ''
                                        ),"readonly":"","label":_vm.$t(
                                            'superAdmin:authenticationPassword'
                                        )}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'bankName',
                                            ''
                                        ),"readonly":"","label":'Nazwa banku'}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'bankAccountNumber',
                                            ''
                                        ),"readonly":"","label":'Numer konta bankowego'}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('Separator',{attrs:{"text":_vm.$t('superAdmin:bussinesLocation')}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'companyCountry',
                                            ''
                                        ),"readonly":"","label":_vm.$t('superAdmin:companyCountry')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'companyCity',
                                            ''
                                        ),"readonly":"","label":_vm.$t('superAdmin:companyCity')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'companyPostCode',
                                            ''
                                        ),"readonly":"","label":_vm.$t('superAdmin:companyPostCode')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'companyStreet',
                                            ''
                                        ),"readonly":"","label":_vm.$t('superAdmin:companyStreet')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'companyHouseNumber',
                                            ''
                                        ),"readonly":"","label":_vm.$t('superAdmin:companyHouseNumber')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'companyApartmentNumber',
                                            ''
                                        ),"readonly":"","label":_vm.$t(
                                            'superAdmin:companyApartmentNumber'
                                        )}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'companyServiceEmail',
                                            ''
                                        ),"readonly":"","label":_vm.$t('superAdmin:companyServiceEmail')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'companyServicePhoneNumber',
                                            ''
                                        ),"readonly":"","label":_vm.$t(
                                            'estimates:companyServicePhoneNumber'
                                        )}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'estimatesPDFBoxText',
                                            ''
                                        ),"readonly":"","label":_vm.$t('estimates:estimatesPDFBoxText')}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'estimatesPDFFooterText',
                                            ''
                                        ),"readonly":"","label":_vm.$t(
                                            'estimates:estimatesPDFFooterText'
                                        )}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'estimatesPDFGuaranteText',
                                            ''
                                        ),"readonly":"","label":_vm.$t(
                                            'estimates:estimatesPDFGuaranteText'
                                        )}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'emailServiceEstimatesHost',
                                            ''
                                        ),"readonly":"","label":'Wyceny - SMTP host'}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'emailServiceEstimatesPort',
                                            ''
                                        ),"readonly":"","label":'Wyceny - SMTP port'}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'emailServiceEstimatesLogin',
                                            ''
                                        ),"readonly":"","label":'Wyceny - SMTP login'}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"dense":"","value":_vm.$get(
                                            _vm.getSettingsDetails,
                                            'emailServiceEstimatesPass',
                                            ''
                                        ),"readonly":"","label":'Wyceny - SMTP pass'}})],1)],1)],1),_vm._t("buttons")],2)],1)],1)],1),_c('Modal',{attrs:{"title":_vm.$t('global:settings'),"open":_vm.open,"height":'570'},on:{"close":function($event){return _vm.closeModal()}}},[_c('AEContentSettings',{attrs:{"slot":"AEContent"},slot:"AEContent"}),_c('ButtonsEditSettings',{attrs:{"slot":"buttons"},on:{"closeModal":function($event){_vm.open = false}},slot:"buttons"})],1),_c('Modal',{attrs:{"title":_vm.$t('superAdmin:editLogo'),"open":_vm.openLogo,"width":"700"},on:{"close":_vm.closeModalLogo}},[_c('AEContent',{attrs:{"slot":"AEContent"},slot:"AEContent"}),_c('Buttons',{attrs:{"slot":"buttons"},on:{"close-modal":function($event){_vm.openLogo = false}},slot:"buttons"})],1),_c('div',{staticClass:"d-flex justify-end"},[_c('OpenModalButtons',{on:{"openModal":function($event){return _vm.openModal()}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }