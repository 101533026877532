<template>
    <div style="position: absolute; bottom: -15px; right: -10px;">
        <v-tooltip left>
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    dark
                    v-on="on"
                    small
                    @click="editSettings()"
                    class="buttons--add margin--1"
                >
                    <v-icon small>mdi-pencil</v-icon>
                </v-btn>
            </template>
            <span>{{ $t('superAdmin:editCompany') }}</span>
        </v-tooltip>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        action: {
            type: String,
        },
    },

    computed: {
        ...mapGetters(['getSettingsDetails']),
    },

    methods: {
        ...mapActions(['fetchSettings']),
        ...mapMutations(['setSettingsModal']),
        editSettings() {
            this.$emit('openModal')
            if (this.getSettingsDetails) this.fetchSettings({ type: 'edit' })
            // this.fetchSettings({ type: 'edit' })
        },
    },
}
</script>
