<template>
    <div>
        <v-btn
            @click="save()"
            :disabled="disabled"
            class="buttons buttons--add"
            >{{ $t('global:save') }}</v-btn
        >
    </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
    i18nOptions: { namespaces: ['profile', 'global'] },
    computed: {
        ...mapGetters(['getProfileDetails']),
    },
    data() {
        return {
            disabled: false,
        }
    },

    methods: {
        ...mapActions(['sendLogo', 'saveSettings']),
        async save() {
            this.disabled = true
            await this.sendLogo({ id: this.getProfileDetails._id })
            // await this.saveSettings()
            this.$emit('close-modal')
            this.disabled = false
        },
    },
}
</script>
